import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Grid from '@material-ui/core/Grid';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select'

import { Checkbox, Input, TextField, Snackbar, FormControl, InputLabel, Tooltip } from '@material-ui/core';
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone";
import MuiAlert from '@material-ui/lab/Alert';

var axios = require("axios");

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//Centersettings funncctional component
export default function CenterSettings() {
    //data states
    const [data, setData] = React.useState(undefined);
    const [centerobjs, setCenterobjs] = React.useState([]);
    const [openFileUpload, setOpenFileUpload] = React.useState(false);

    //snackbar states
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

    //close Snackbar
    function handleSnackbarClose() {
        setOpenSnackbar(false);
        setSnackbarMsg("");
    }

    const classes = useStyles();

    //fetch user and center data if undefined
    if (data == undefined) {
        axios.post(window.serverip + "/getUserDataByID/" + window.loggedinid, {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                setData(response.data[0]);
                var centerids = JSON.parse(response.data[0].centerids);
                var tempcenterobjs = [];
                for (let i = 0; i < centerids.length; i++) {
                    const centerid = centerids[i];
                    axios.post(window.serverip + "/getCenterbyId/" + centerid, {
                        sessionid: sessionStorage.getItem("sessionid")
                    })
                        .then((response) => {
                            tempcenterobjs.push(response.data[0]);
                            if (tempcenterobjs.length === centerids.length) {
                                setCenterobjs(tempcenterobjs);
                            }
                        }, (error) => {
                            console.log(error);
                        });
                }
            }, (error) => {
                console.log(error);
            });
        return null;
    }


    return (
        <Grid container>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} >
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <Card>
                <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Center settings</h4>
                    <p className={classes.cardCategoryWhite}>Edit your center settings.</p>
                </CardHeader>
                <CardBody>
                    {centerobjs.map((cobj, key) => {
                        //console.log(key);
                        //console.log(cobj);
                        //console.log(JSON.parse(cobj.socialmediaobj));
                        function changeFieldofCobj(field, value) {
                            var tempcenterobjs = [...centerobjs];
                            tempcenterobjs[key][field] = value;
                            setCenterobjs(tempcenterobjs);
                        }
                        function changeSocialMedia(field, field2, value) {
                            var tempsocialmediaobj = JSON.parse(cobj.socialmediaobj);
                            tempsocialmediaobj[field][field2] = value;
                            tempsocialmediaobj = JSON.stringify(tempsocialmediaobj);
                            changeFieldofCobj("socialmediaobj", tempsocialmediaobj);
                        }

                        function saveData() {
                            for (let i = 0; i < centerobjs.length; i++) {
                                const element = centerobjs[i];
                                element.sessionid = sessionStorage.getItem("sessionid");
                                axios.post(window.serverip + "/setCenterData/" + cobj.id, element)
                                    .then((response) => {
                                        //console.log(response.data);
                                        setSnackbarSeverity("success");
                                        setSnackbarMsg("Centersettings changed successfully");
                                        setOpenSnackbar(true);
                                    }, (error) => {
                                        //console.log(error);
                                        setSnackbarSeverity("error");
                                        setSnackbarMsg("network error");
                                        setOpenSnackbar(true);
                                    });
                            }
                        }


                        return <><Grid container spacing={3} item xs={12} key={key}>
                            <Grid container item xs={6} spacing={1} justifyContent="center"
                                alignItems="center">
                                <Grid item xs={12}>
                                    <h6>Center: {cobj.centername}</h6>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label="Centername" value={cobj.centername} variant="outlined" onChange={(e) => { changeFieldofCobj("centername", e.target.value) }}></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label="Website" value={cobj.website} variant="outlined" onChange={(e) => { changeFieldofCobj("website", e.target.value) }}></TextField>
                                </Grid>
                                <Grid container item xs={12} >
                                    <Tooltip title="Change the default language of the Devices">
                                        <FormControl fullWidth>
                                            <InputLabel>Language</InputLabel>
                                            <Select

                                                value={cobj.defaultlang}
                                                onChange={(e) => { changeFieldofCobj("defaultlang", e.target.value) }}
                                            >
                                                <MenuItem value={"en"}>English</MenuItem>
                                                <MenuItem value={"de"}>German</MenuItem>
                                                <MenuItem value={"it"}>Italian</MenuItem>
                                                <MenuItem value={"pl"}>Polish</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={8} >
                                    Logo:<br></br>
                                    <img src={cobj.centerlogo} style={{ maxWidth: "100%" }} alt="Please set a Logo first." ></img>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DropzoneDialog acceptedFiles={["image/png"]} filesLimit={1} onClose={() => setOpenFileUpload(false)} open={openFileUpload} filesLimit={1} onChange={(files) => {
                                        //var files = e.target.files;
                                        if (files.length > 0) {
                                            var reader = new FileReader();
                                            reader.readAsDataURL(files[0]);
                                            reader.onload = (function (file) {
                                                //console.log(reader.result);
                                                changeFieldofCobj("centerlogo", reader.result);
                                                setOpenFileUpload(false);
                                            });
                                        }
                                    }}></DropzoneDialog>
                                    <Button fullWidth onClick={() => setOpenFileUpload(true)}>Upload logo</Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} spacing={2}>
                                <Grid item xs={12}>
                                    <h6>Social media links for e-mails</h6>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={JSON.parse(cobj.socialmediaobj).facebook.link} label="Facebook" onChange={(e) => {
                                        changeSocialMedia("facebook", "link", e.target.value)
                                    }}></TextField>
                                    <Checkbox checked={(JSON.parse(cobj.socialmediaobj).facebook.enabled == "true")} onChange={(e) => {
                                        changeSocialMedia("facebook", "enabled", e.target.checked ? "true" : "false")
                                    }}></Checkbox>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={JSON.parse(cobj.socialmediaobj).instagram.link} label="Instagram" onChange={(e) => {
                                        changeSocialMedia("instagram", "link", e.target.value)
                                    }}></TextField>
                                    <Checkbox checked={(JSON.parse(cobj.socialmediaobj).instagram.enabled == "true")} onChange={(e) => {
                                        changeSocialMedia("instagram", "enabled", e.target.checked ? "true" : "false")
                                    }}></Checkbox>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={JSON.parse(cobj.socialmediaobj).twitter.link} label="Twitter" onChange={(e) => {
                                        changeSocialMedia("twitter", "link", e.target.value)
                                    }}></TextField>
                                    <Checkbox checked={(JSON.parse(cobj.socialmediaobj).twitter.enabled == "true")} onChange={(e) => {
                                        changeSocialMedia("twitter", "enabled", e.target.checked ? "true" : "false")
                                    }}></Checkbox>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={JSON.parse(cobj.socialmediaobj).youtube.link} label="Youtube" onChange={(e) => {
                                        changeSocialMedia("youtube", "link", e.target.value)
                                    }}></TextField>
                                    <Checkbox checked={(JSON.parse(cobj.socialmediaobj).youtube.enabled == "true")} onChange={(e) => {
                                        changeSocialMedia("youtube", "enabled", e.target.checked ? "true" : "false")
                                    }}></Checkbox>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={JSON.parse(cobj.socialmediaobj).foursquare.link} label="Foursquare" onChange={(e) => {
                                        changeSocialMedia("foursquare", "link", e.target.value)
                                    }}></TextField>
                                    <Checkbox checked={(JSON.parse(cobj.socialmediaobj).foursquare.enabled == "true")} onChange={(e) => {
                                        changeSocialMedia("foursquare", "enabled", e.target.checked ? "true" : "false")
                                    }}></Checkbox>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={JSON.parse(cobj.socialmediaobj).yelp.link} label="Yelp" onChange={(e) => {
                                        changeSocialMedia("yelp", "link", e.target.value)
                                    }}></TextField>
                                    <Checkbox checked={(JSON.parse(cobj.socialmediaobj).yelp.enabled == "true")} onChange={(e) => {
                                        changeSocialMedia("yelp", "enabled", e.target.checked ? "true" : "false")
                                    }}></Checkbox>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={JSON.parse(cobj.socialmediaobj).androidapp.link} label="Android App" onChange={(e) => {
                                        changeSocialMedia("androidapp", "link", e.target.value)
                                    }}></TextField>
                                    <Checkbox checked={(JSON.parse(cobj.socialmediaobj).androidapp.enabled == "true")} onChange={(e) => {
                                        changeSocialMedia("androidapp", "enabled", e.target.checked ? "true" : "false")
                                    }}></Checkbox>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={JSON.parse(cobj.socialmediaobj).iosapp.link} label="IOS App" onChange={(e) => {
                                        changeSocialMedia("iosapp", "link", e.target.value)
                                    }}></TextField>
                                    <Checkbox checked={(JSON.parse(cobj.socialmediaobj).iosapp.enabled == "true")} onChange={(e) => {
                                        changeSocialMedia("iosapp", "enabled", e.target.checked ? "true" : "false")
                                    }}></Checkbox>
                                </Grid>
                            </Grid>
                        </Grid>
                            <CardFooter>
                                <Button color="primary" onClick={saveData}>Save settings</Button>
                            </CardFooter>
                        </>;
                    })}
                </CardBody>
            </Card>
        </Grid>
    );
}
