import React, { useEffect } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import EmailIcon from '@material-ui/icons/Email';
import CropFreeIcon from '@material-ui/icons/CropFree';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Statistics from "views/Statistics/Statistics";

const axios = require("axios");

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const devicearray = window.deviceids;
  const isDashboard = true;
  //statistics state
  const [imagetaken, setImageTaken] = React.useState(0);
  const [email, setEmail] = React.useState(0);
  const [qr, setQr] = React.useState(0);

  useEffect(() => {

    var statisticstemp = {};
    for (let i = 0; i < devicearray.length; i++) {
      const boxid = devicearray[i];
      axios.post(window.serverip + "/statisticsbyboxid/" + boxid, {
        sessionid: sessionStorage.getItem("sessionid")
      })
        .then((response) => {
          statisticstemp[boxid] = response.data;
          var countPic = 0;
          var countEmail = 0;
          var countQr = 0;
          if (Object.keys(statisticstemp).length == devicearray.length) {
            statisticstemp[boxid].forEach(statistic => {
              if (statistic.action == 0) {
                countPic++
              }
              if (statistic.action == 1) {
                countEmail++
              }
              if (statistic.action == 2) {
                countQr++
              }

            });
            // for (const key in statisticstemp[boxid]) {
            //   console.log(statisticstemp[boxid][key]["action"]);
            //   if (statisticstemp[boxid][key]["action"] == 0) {
            //     console.log("countPic++");
            //     countPic++
            //   }
            //   else if (statisticstemp[boxid][key]["action"] == 1) {
            //     countEmail++
            //   }
            //   else if (statisticstemp[boxid][key]["action"] == 2) {
            //     countQr++
            //   }

            //   // const element = statisticstemp[key];
            //   // console.log(element);
            //   // amount = amount + element.length;
            // }
          }
          setImageTaken(countPic)
          setEmail(countEmail)
          setQr(countQr)
          // if (imagetaken != amount) {
          //   setImageTaken(amount);
          // }

        }, (error) => {
          console.log(error);
        });
    }
  }, [])
  // if (imagetaken == 0) {
  //   var statisticstemp = {};
  //   for (let i = 0; i < devicearray.length; i++) {
  //     const boxid = devicearray[i];
  //     axios.post(window.serverip + "/statisticsbyboxid/" + boxid, {
  //       sessionid: sessionStorage.getItem("sessionid")
  //     })
  //       .then((response) => {
  //         console.log(response.data);
  //         statisticstemp[boxid] = response.data;
  //         var amount = 0;
  //         if (Object.keys(statisticstemp).length == devicearray.length) {
  //           //console.log("both fetched");
  //           console.log(statisticstemp);
  //           for (const key in statisticstemp) {
  //             console.log(key);
  //             const element = statisticstemp[key];
  //             amount = amount + element.length;
  //           }
  //           if (imagetaken != amount) {
  //             setImageTaken(amount);
  //           }
  //         }
  //       }, (error) => {
  //         console.log(error);
  //       });
  //   }
  // }

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Fotoboxes</p>
              <h3 className={classes.cardTitle}>
                {devicearray.length}
              </h3>
            </CardHeader>
            <CardFooter stats>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <AddAPhotoIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Pictures</p>
              <h3 className={classes.cardTitle}>{imagetaken}</h3>
            </CardHeader>
            <CardFooter stats>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <EmailIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Email</p>
              <h3 className={classes.cardTitle}>{email}</h3>
            </CardHeader>
            <CardFooter stats>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <CropFreeIcon />
              </CardIcon>
              <p className={classes.cardCategory}>QR</p>
              <h3 className={classes.cardTitle}>{qr}</h3>
            </CardHeader>
            <CardFooter stats>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Statistics nobar isDashboard={isDashboard}></Statistics>
        </GridItem>
      </GridContainer>
    </div>
  );
}
