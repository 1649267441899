import React from 'react';
import Grid from '@material-ui/core/Grid';
// import SceneMedia from './SceneMedia';
import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

var axios = require("axios");
const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
        padding: 2
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default function Scene(props) {
    const [deleted, setDeleted] = React.useState(false);

    var sceneid = props.sceneid;
    var sceneids = props.sceneids;
    var setSceneids = props.setSceneids;
    let checked = sceneids.includes(sceneid);
    console.log(checked);
    if (deleted) {
        return null;
    }

    function deleteCustomScene() {
        const centerid = window.centerids[0];
        axios.post(window.serverip + "/deleteCustomScene/" + centerid + "/" + sceneid, {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                if (response.data.error != undefined && response.data.error === "in_use") {
                    // alert("You can't delete scenes which are in use.")
                    props.setSnackbarSeverity("error");
                    props.setSnackbarMsg("You can't delete scenes which are in use.");
                    props.setOpenSnackbar(true);
                } else {
                    setDeleted(true);
                    props.setSnackbarSeverity("success");
                    props.setSnackbarMsg("Custom scene deleted");
                    props.setOpenSnackbar(true);
                }
            }, (error) => {
                console.log(error);
            });
    }

    if (props.customScene) {
        let backgroundimg = props.customScene.background;

        return (
            <Grid item xs={6} sm={2}>
                <Tooltip title={props.sceneids.length != 20 ? sceneids.length + " / 20 scenes selected" : "20 scenes selected!"}>
                    <Card >
                        <CardHeader
                            avatar={
                                <Avatar aria-label="recipe" style={{ backgroundColor: checked ? "#52d869" : "" }}>
                                    {sceneid}
                                </Avatar>
                            }
                            action={
                                <Tooltip title={"Delete custom scene!"}>
                                    <IconButton onClick={deleteCustomScene} >
                                        <Delete></Delete>
                                    </IconButton>
                                </Tooltip>
                            }
                            title={<FormControl component="fieldset">
                                <FormControlLabel
                                    control={<IOSSwitch checked={checked} onChange={checkScene} name="checked" />}
                                />
                            </FormControl>}
                        />
                        <CardMedia
                            style={{ paddingTop: '56.25%', }}
                            image={backgroundimg}
                            title={"Custom"}
                        />
                    </Card>
                </Tooltip>
            </Grid>)
    }
    else {
        let backgroundimg = window.serverurl + ":5000/scenes/" + sceneid + "_back.png";
        return (
            <Grid item xs={6} sm={2}>
                <Tooltip title={props.sceneids.length != 20 ? sceneids.length + " / 20 scenes selected" : "20 scenes selected!"}>
                    <Card >
                        <CardHeader
                            avatar={
                                <Avatar aria-label="recipe" style={{ backgroundColor: checked ? "#52d869" : "" }}>
                                    {sceneid}
                                </Avatar>
                            }
                            title={<FormControl component="fieldset">
                                <FormControlLabel
                                    control={<IOSSwitch checked={checked} onChange={checkScene} name="checked" />}
                                />
                            </FormControl>}
                        />
                        <CardMedia
                            style={{ paddingTop: '56.25%', }}
                            image={backgroundimg}
                            title={"Scene "}
                        />
                    </Card>
                </Tooltip>
            </Grid>)
    }

    function checkScene(e) {
        if (sceneids.includes(sceneid)) {
            var arraycopy = sceneids.filter(function (value, index, arr) { return value != sceneid; });
            setSceneids(arraycopy);
        } else {
            if (sceneids.length != 20) {
                var arraycopy = [...sceneids];
                arraycopy.push(sceneid);
                arraycopy.sort((a, b) => a - b)
                setSceneids(arraycopy);
            }
            else {
                props.setSnackbarSeverity("error");
                props.setSnackbarMsg("Already 20 scenes selected!");
                props.setOpenSnackbar(true);
            }
        }
    }
}
