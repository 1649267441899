import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, Paper, Snackbar } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { v4 as uuidv4 } from 'uuid';
import MuiAlert from '@material-ui/lab/Alert';
import Scene from './Scene';

var axios = require("axios");

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
}));

export default function SelfieBoxList(props) {
    const [customScenes, setCustomScenes] = React.useState(undefined);
    const classes = useStyles();

    if (customScenes == undefined) {
        axios.post(window.serverip + "/getCustomScenes/" + window.centerids[0], {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                setCustomScenes(response.data);
            }, (error) => {
                console.log(error);
            });
        return <><CircularProgress></CircularProgress><br></br>Loading Scenes</>;
    }

    var devicearray = window.deviceids;

    return (

        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Scenes</h4>
                <p className={classes.cardCategoryWhite}>You need to select 20 scenes and click on the "SAVE SCENE" button to execute the scene change</p>
            </CardHeader>
            <CardBody>

                {devicearray.map((device) => <SelfieBoxListEntry id={device} key={device} customScenes={customScenes} onlyentry={devicearray.length === 1}></SelfieBoxListEntry>)}

            </CardBody>
        </Card>


    )
}

function SelfieBoxListEntry(props) {
    const [data, setData] = React.useState(undefined);
    const [expanded, setExpanded] = React.useState(props.onlyentry);
    const classes = useStyles();
    var boxid = props.id;
    if (data === undefined) {
        axios.post(window.serverip + "/getDevicebyID/" + boxid, {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                setData(response.data[0]);
            }, (error) => {
                console.log(error);
            });
        return null;
    }

    var name = "Loading Boxdata...";
    if (data != undefined) {
        name = "Box " + data.location;
    }

    return (
        <Accordion expanded={expanded} onChange={(e, bool) => {
            setExpanded(bool);
        }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onChange={(e) => {
                    console.log(e.target.expanded);
                }}
            >
                <Typography className={classes.heading}>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {expanded ? <Details boxid={boxid} customScenes={props.customScenes}></Details> : null}
            </AccordionDetails>
        </Accordion>
    )
}

function Details(props) {
    const [data, setData] = React.useState(undefined);
    var boxid = props.boxid;
    if (data == undefined) {
        axios.post(window.serverip + "/getScenesbyDeviceID/" + boxid, {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                setData(response.data[0])
            }, (error) => {
                console.log(error);
            });
        return null;
    }
    var sceneids = [];
    for (const [key, value] of Object.entries(data)) {
        if (key.startsWith("scene")) {
            sceneids.push(value);
        }
    }

    return (<ScenesWithSelectedScenes sceneids={sceneids} boxid={boxid} customScenes={props.customScenes}></ScenesWithSelectedScenes>)
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ScenesWithSelectedScenes(props) {
    const [sceneids, setSceneids] = React.useState(props.sceneids);
    const [scenesFolderArray, setScenesFolderArray] = React.useState(undefined);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
    const customScenes = props.customScenes;

    var boxid = props.boxid;
    var availablescenes = [];

    useEffect(() => {
        axios.post(window.serverip + "/getScenesFolder", {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                var respData = JSON.parse(response.data)
                respData.forEach((scene, i) => {
                    var id = parseInt(scene.slice(-0, -9));
                    if (id > 0) {
                        if (scene.includes("_back.png")) {
                            availablescenes.push({ sceneid: id, type: "photo" });
                        }
                        else if (scene.includes("_back.mp4")) {
                            availablescenes.push({ sceneid: id, type: "video" });
                        }
                    }
                });
                availablescenes.sort((a, b) => a.sceneid - b.sceneid);
                setScenesFolderArray(availablescenes)
            }, (error) => {
                console.log(error);
            });
    }, []);

    // for (let i = 1; i <= 101; i++) {
    //     if (i != 92) {
    //         availablescenes.push({ sceneid: i, type: "photo" });
    //         if (i == 29 || i == 42 || i == 43 || i == 44 || i == 94) {
    //             availablescenes[availablescenes.length - 1].type = "video";
    //         }
    //     }
    // }


    function handleSnackbarClose() {
        setOpenSnackbar(false);
        setSnackbarMsg("");
    }

    function handleSceneChange() {
        if (sceneids.length != 20) {
            setSnackbarSeverity("error");
            setSnackbarMsg("You need to select 20 scenes to proceed");
            setOpenSnackbar(true);
            return;
        }
        axios.post(window.serverip + "/setScenes/" + boxid, {
            scenes: sceneids,
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                //console.log(response.data[0]);
                if (window.rank === 0) {
                    setSnackbarSeverity("warning");
                    setSnackbarMsg("We will deal with your application as soon as possible");
                    setOpenSnackbar(true);
                } else if (window.rank >= 1) {
                    setSnackbarSeverity("success");
                    setSnackbarMsg("Scenes changed successfully");
                    setOpenSnackbar(true);
                }
            }, (error) => {
                //console.log(error);
                setSnackbarSeverity("error");
                setSnackbarMsg("Network error");
                setOpenSnackbar(true);
            });
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <p >Selected scenes {sceneids.length} / 20</p>
        </Grid>
        <Grid item xs={12}>
            {scenesFolderArray ?


                <Grid container spacing={2}>
                    {scenesFolderArray.map((scene) => { return <Scene key={scene.sceneid} sceneid={scene.sceneid} type={scene.type} sceneids={sceneids} setSceneids={setSceneids} setOpenSnackbar={setOpenSnackbar} setSnackbarMsg={setSnackbarMsg} setSnackbarSeverity={setSnackbarSeverity} ></Scene> })}
                    {customScenes.map((scene) => <Scene key={scene.custom_sceneid} sceneid={scene.custom_sceneid} sceneids={sceneids} setSceneids={setSceneids} customScene={scene} setOpenSnackbar={setOpenSnackbar} setSnackbarMsg={setSnackbarMsg} setSnackbarSeverity={setSnackbarSeverity} ></Scene>)}
                </Grid>


                : null
            }
        </Grid>
        <Grid item xs={12}>
            <Button color="primary" onClick={handleSceneChange}>Save scenes</Button>
        </Grid>

        <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} >
                {snackbarMsg}
            </Alert>
        </Snackbar>
    </Grid>

}

