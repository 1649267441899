import { AddCircle } from "@material-ui/icons";
/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Build from "@material-ui/icons/Build";
import List from "@material-ui/icons/List";
import BarChart from "@material-ui/icons/BarChart";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import AddScene from "views/AddScene/AddScene.js";
import CenterSettings from "views/Centersettings/Centersettings.js";
import UserEdit from "views/UserEdit/UserEdit.js";
import SelfieBoxList from "views/SelfieBoxList/SelfieBoxList";
import Statistics from "views/Statistics/Statistics";


//These routes are loaded in the Layout
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/boxlist",
    name: "Scenes",
    rtlName: "لوحة القيادة",
    icon: List,
    component: SelfieBoxList,
    layout: "/admin"
  },
  {
    path: "/statistics",
    name: "Statistics",
    rtlName: "لوحة القيادة",
    icon: BarChart,
    component: Statistics,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "Profile settings",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/center",
    name: "Center settings",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Build,
    component: CenterSettings,
    layout: "/admin"
  },
  {
    path: "/newscene",
    name: "Add new Scene",
    rtlName: "ملف تعريفي للمستخدم",
    icon: AddCircle,
    component: AddScene,
    layout: "/admin"
  },
  {
    path: "/edituser",
    name: "Edit Users",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserEdit,
    layout: "/admin"
  },
];

export default dashboardRoutes;
