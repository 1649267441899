import { CircularProgress, Paper } from "@material-ui/core";
import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import axios from "axios";
import SceneMedia from "views/SelfieBoxList/SceneMedia";
import Grid from '@material-ui/core/Grid';
import Card from "components/Card/Card.js";
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import LinkedCameraIcon from '@material-ui/icons/LinkedCamera';
import Badge from '@material-ui/core/Badge';


export default function DataPreparer(props) {
    const [customScenes, setCustomScenes] = React.useState(undefined);

    if (!props.nobar && customScenes == undefined) {
        axios.post(window.serverip + "/getCustomScenes/" + window.centerids[0], {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                setCustomScenes(response.data);
            }, (error) => {
                console.log(error);
            });
        return <><CircularProgress></CircularProgress><br></br>Loading Scenes</>;
    }

    var years = props.years;
    var selectedDay = props.selectedDay;
    var selectedYear = props.selectedYear;
    var selectedMonth = props.selectedMonth;

    var processeddata = [];
    var filtered;

    function noData() {
        return "No data found to display..."
    }

    var scenestatisticcounter = [];

    function increaseScenestatisticCounter(scene_id) {
        if (scenestatisticcounter[scene_id] == undefined) {
            scenestatisticcounter[scene_id] = 1;
        } else {
            scenestatisticcounter[scene_id]++;
        }
    }

    if (selectedDay != "none") {
        if (years[selectedYear][selectedMonth] == undefined) {
            return noData();
        }
        var datatoprocess = years[selectedYear][selectedMonth][selectedDay];
        if (datatoprocess == undefined) {
            return noData();
        }
        var timearr = ["00 - 08", "08 - 10", "10 - 12", "12 - 14", "14 - 16", "16 - 18", "18 - 20", "20 - 24"];
        for (let i = 0; i < timearr.length; i++) {
            processeddata.push({ name: timearr[i] + " Uhr", bilder: 0, email: 0, qr: 0 });
        }

        function getHourInterval(hour) {
            if (hour < 8) {
                return 0;
            }
            if (hour < 10) {
                return 1;
            }
            if (hour < 12) {
                return 2;
            }
            if (hour < 14) {
                return 3;
            }
            if (hour < 16) {
                return 4;
            }
            if (hour < 18) {
                return 5;
            }
            if (hour < 20) {
                return 6;
            }
            return 7;
        }

        for (let i = 0; i < datatoprocess.length; i++) {
            const element = datatoprocess[i];
            increaseScenestatisticCounter(element.scene_id);

            var date = new Date(element.timestamp);
            var hourinterval = getHourInterval(date.getHours());
            processeddata[hourinterval].bilder = processeddata[hourinterval].bilder + 1;
            if (element.action == 1) {
                processeddata[hourinterval].email = processeddata[hourinterval].email + 1;
            } else if (element.action == 2) {
                processeddata[hourinterval].qr = processeddata[hourinterval].qr + 1;
            }
        }

        filtered = processeddata;
    } else if (selectedMonth != "none") {
        var numberofdays = new Date(selectedYear, selectedMonth, 0).getDate();
        for (let j = 1; j <= numberofdays; j++) {
            if (years[selectedYear][selectedMonth] == undefined) {
                return noData();
            }
            processeddata[j] = { name: j + ". " + (selectedMonth), bilder: 0, email: 0, qr: 0 };

            var datatoprocess = years[selectedYear][selectedMonth][j];
            if (datatoprocess == undefined) {
                continue;
            }
            for (let i = 0; i < datatoprocess.length; i++) {
                const element = datatoprocess[i];
                increaseScenestatisticCounter(element.scene_id);

                processeddata[j].bilder = processeddata[j].bilder + 1;

                if (element.action == 1) {
                    processeddata[j].email = processeddata[j].email + 1;
                } else if (element.action == 2) {
                    processeddata[j].qr = processeddata[j].qr + 1;
                }
            }
        }
        var filtered = processeddata.filter(function (el) {
            return el != null;
        });
    } else if (selectedYear != "none") {
        //console.log(processeddata);
        for (let i = 1; i <= 12; i++) {
            //console.log(processeddata);
            processeddata.push({ name: getMonth(i), bilder: 0, email: 0, qr: 0 });
            //console.log(processeddata);
        }
        //console.log(processeddata);
        function getMonth(month) {
            if (month == 1) {
                return "January";
            } else if (month == 2) {
                return "February";
            } else if (month == 3) {
                return "March";
            } else if (month == 4) {
                return "April";
            } else if (month == 5) {
                return "May";
            } else if (month == 6) {
                return "June";
            } else if (month == 7) {
                return "July";
            } else if (month == 8) {
                return "August";
            } else if (month == 9) {
                return "September";
            } else if (month == 10) {
                return "October";
            } else if (month == 11) {
                return "November";
            } else if (month == 12) {
                return "December";
            }
        }

        var i = 0;
        //console.log(processeddata);
        //console.log(years);

        for (const key in years[selectedYear]) {
            const month = years[selectedYear][key];
            for (const key2 in month) {
                //console.log(key);
                const element = month[key2];
                for (let k = 0; k < element.length; k++) {
                    const element2 = element[k];
                    increaseScenestatisticCounter(element2.scene_id);
                    processeddata[key - 1].bilder = processeddata[key - 1].bilder + 1;

                    if (element2.action == 1) {
                        processeddata[key - 1].email = processeddata[key - 1].email + 1;
                    } else if (element2.action == 2) {
                        processeddata[key - 1].qr = processeddata[key - 1].qr + 1;
                    }
                }
            }
            i++;
        }

        filtered = processeddata;
        //console.log(filtered);
    }

    if (filtered == undefined) {
        return (<>Please specify a date to display statistics.</>)
    }

    let statisticssceneslength = 0;
    for (let i = 0; i < scenestatisticcounter.length; i++) {
        const stat = scenestatisticcounter[i];
        if (stat != undefined) {
            statisticssceneslength++;
        }
    }
    return (<>
        <DataDisplayer data={filtered} scenestatisticcounter={scenestatisticcounter}></DataDisplayer>
        {props.nobar ? null : <>
            Scene distribution:
            <Grid container spacing={2}>
                {scenestatisticcounter.map(function (item, i) {
                    if (i < 1000) {
                        return <Grid item sm={2} xs={6} key={i}>
                            <Card>
                                <CardMedia
                                    style={{ paddingTop: '56.25%', }}
                                    image={window.serverurl + ":5000/scenes/" + i + "_back.png"}
                                    title={"Scene "}
                                />
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe">
                                            {i}
                                        </Avatar>
                                    }
                                    title={<Badge badgeContent={item} color="primary">
                                        <LinkedCameraIcon />
                                    </Badge>}
                                    titleTypographyProps={{ align: 'right' }}
                                />
                            </Card>
                        </Grid>
                            ;
                    } else {
                        for (let i = 0; i < customScenes.length; i++) {
                            const customScene = customScenes[i];
                            if (customScene.custom_sceneid === i) {
                                let backgroundimg = customScene.background;
                                return <Grid item sm={2} xs={6} key={i}>
                                    <Card >
                                        <CardMedia
                                            style={{ paddingTop: '56.25%', }}
                                            image={backgroundimg}
                                            title={"Scene "}
                                        />
                                        <CardHeader
                                            avatar={
                                                <Avatar aria-label="recipe">
                                                    {i}
                                                </Avatar>
                                            }
                                            title={<Badge badgeContent={item} color="primary">
                                                <LinkedCameraIcon />
                                            </Badge>}
                                            titleTypographyProps={{ align: 'right' }} />

                                    </Card>
                                </Grid>
                            }
                        }
                        return null;
                    }
                })}

            </Grid>


            {/* <table style={{ width: "100%", border: "1px solid black" }}>
                <thead>
                    <tr>
                        <th style={{ width: "20%" }}>Szene:</th>
                        {scenestatisticcounter.map(function (item, i) {
                            if (i < 1000) {
                                return <th key={i} style={{ border: "1px solid black", textAlign: "right" }}><img style={{ width: "100%" }} src={window.serverurl + ":5000/scenes/" + i + "_back.png"}></img></th>;
                            } else {
                                for (let i = 0; i < customScenes.length; i++) {
                                    const customScene = customScenes[i];
                                    if (customScene.custom_sceneid === i) {
                                        let backgroundimg = customScene.background;
                                        return <th key={i} style={{ border: "1px solid black", textAlign: "right" }}><img style={{ width: "100%" }} src={backgroundimg}></img></th>;
                                    }
                                }
                                return null;
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th style={{ width: "20%" }}>Pictures taken:</th>
                        {scenestatisticcounter.map(function (item, i) {
                            return <td key={i} style={{ border: "1px solid black", textAlign: "right" }}>{item}</td>;
                        })}
                    </tr>
                </tbody>
            </table> */}

        </>}

    </>);
}

function DataDisplayer(props) {
    var data = props.data;

    return (
        <ResponsiveContainer width={"100%"} height={300}>
            <BarChart
                data={data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip></Tooltip>
                {/*<Tooltip content={<CustomTooltip scenestatisticcounter={props.scenestatisticcounter}></CustomTooltip>} /> */}
                <Legend />
                <Bar dataKey="bilder" name="Pictures" fill="#8884d8" />
                <Bar dataKey="email" name="E-Mail" fill="#82ca9d" />
                <Bar dataKey="qr" name="QR-Code" fill="#42fa9d" />
            </BarChart>
        </ResponsiveContainer>
    )
}

function CustomTooltip(props) {
    var active = props.active;
    var payload = props.payload;
    var scenestatisticcounter = props.scenestatisticcounter;

    if (!active) return null;

    return <Paper style={{ padding: "2px 5px" }}>
        {payload.map(function (item, i) {
            return <p key={i} style={{ color: item.fill }}>{item.dataKey}: {item.value}<br></br></p>
        })}
        <br></br>
    </Paper>
}
