import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
// import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import Centerlogo from "views/Centerlogo/Centerlogo";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
// import logo from "assets/img/logo_blue.png";
import logoHeader from "assets/img/fotobox_logo.png";
import { Paper } from "@material-ui/core";
import LoginPage from 'views/LoginPage/LoginPage'

var axios = require("axios");
let ps;

//Set global serverurl
//window.serverip = window.location.hostname + ":5000"
// window.serverurl = "https://" + window.location.hostname;
window.serverurl = "https://fotobox.ecknology.de"
window.serverip = window.serverurl + ":5000";

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = image => {
    setImage(image);
  };

  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };


  const [userLoggedIn, setLoggedin] = React.useState(undefined);
  const [tried, setTried] = React.useState(false);
  const [rememberme, setRememberMe] = React.useState(false);
  const [loginerror1, setLoginError1] = React.useState(false);
  const [loginerror2, setLoginError2] = React.useState(false);

  //autologin on rememberme
  if (sessionStorage.getItem("remembered")) {
    if (!userLoggedIn && !tried) {
      let sessionid = sessionStorage.getItem("sessionid");
      axios.post(window.serverip + "/getSessionObjbyId", {
        sessionid: sessionid
      })
        .then((response) => {
          setTried(true);
          if (response.data.userid != undefined) {
            window.loggedinid = response.data.userid;
            window.rank = response.data.rank;
            window.deviceids = response.data.deviceids;
            window.centerids = response.data.centerids;
            setLoggedin(true);
          }
        }, (error) => {
          console.log(error);
        });
    }
  }

  //login function
  function login() {
    setLoginError1(false);
    setLoginError2(false);

    try {
      axios.post(window.serverip + "/login", {
        email: email,
        pw: password
      })
        .then((response) => {
          if (response.data[0] != undefined) {
            if (rememberme) {
              sessionStorage.setItem("remembered", true);
            }
            sessionStorage.setItem("sessionid", response.data[0].session.sessionid);
            window.loggedinid = response.data[0].id;
            window.rank = response.data[0].rank;
            window.deviceids = JSON.parse(response.data[0].deviceids);
            window.centerids = JSON.parse(response.data[0].centerids);
            setLoggedin(true);
          } else {
            setLoginError1(true);
            setLoginError2(true);
            setTimeout(() => {
              setLoginError1(false);
              setLoginError2(false);
            }, 3000);
          }
        }, (error) => {
          console.log(error);
        });
    } catch (error) {

    }
  }

  //Login Component
  if (userLoggedIn == undefined) {
    return <LoginPage rememberme={rememberme} loginerror1={loginerror1} loginerror2={loginerror2} setRememberMe={setRememberMe} loginClick={login} email={email} passwort={password} setEmail={setEmail} setPassword={setPassword}></LoginPage>
  }

  //generate Routes, depending on rannk
  var generatedRoutes = [];
  if (window.rank == 0) {
    generatedRoutes.push(routes[0]);
    generatedRoutes.push(routes[1]);
    generatedRoutes.push(routes[2]);
    generatedRoutes.push(routes[3]);
    generatedRoutes.push(routes[4]);
  } else if (window.rank == 1) {
    generatedRoutes.push(routes[0]);
    generatedRoutes.push(routes[1]);
    generatedRoutes.push(routes[2]);
    generatedRoutes.push(routes[3]);
    generatedRoutes.push(routes[4]);
    generatedRoutes.push(routes[5]);
  } else if (window.rank == 2) {
    generatedRoutes.push(routes[6]);
  }

  //Switch with Routes
  const switchRoutes = (
    <Switch>
      {generatedRoutes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );

  //Main Component, 
  //Holds Sidebar, Current View, Footer.
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={generatedRoutes}
        logoText={"Ecknology Fotobox"}
        logo={logoHeader}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

        <Paper style={{ backgroundColor: "#E3E2E2" }}>
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                {switchRoutes}
              </div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
        </Paper>
        {getRoute() ? <Footer routes={generatedRoutes} /> : null}
      </div>
    </div>
  );
}
