/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import { Link } from "react-router-dom";

import logo from "assets/img/logo_email.png"

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            {props.routes.map((route, key) => {
              return (
                <ListItem key={key} className={classes.inlineBlock} disabled={route.disabled}>
                  <Link to={route.layout + route.path}
                    onClick={(e) => {
                      if (route.disabled) {
                        e.preventDefault();
                      }
                    }}>{route.name}</Link>
                </ListItem>
              )
            })}
          </List>
        </div>
        <p className={classes.right}>
          <span>
            <a
              href="https://www.ecknology.de"
              target="_blank"
              className={classes.a}
            >
              <img src={logo} style={{ maxHeight: "20px" }}></img>
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}
