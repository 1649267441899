import React from 'react'

function UserEdit() {
    const [data, setData] = React.useState(undefined);

    if(data == undefined){
        fetch(window.serverip + "/getUsers/")
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setData(data);
            });
        return null;
    }

    return (
        <div>
            {JSON.stringify(data)}
        </div>
    )
}

export default UserEdit
