import React, { useEffect } from "react";

import MaterialTable from 'material-table';

export default function UserTable(props) {

    return (
        <MaterialTable

            title="Email addresses"
            columns={props.columns}
            data={props.statisticsMailer}
            options={{
                exportButton: true,
                exportAllData: true
            }}
        />
    )
}
