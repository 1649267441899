import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";

import { Select, MenuItem, Typography } from "@material-ui/core";
import DataPreparer from "./DataDisplayer";
import UserTable from "./UserTable";

var axios = require("axios");


const dateObj = new Date()

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function Statistics(props) {
    const classes = useStyles();

    const [fetchStatisticsMailer, setFetchStatisticsMailer] = React.useState(true);
    const [statisticsMailer, setStatisticsMailer] = React.useState(undefined);
    const [statistics, setStatistics] = React.useState(undefined);
    const [selectedYear, setSelectedYear] = React.useState(dateObj.getUTCFullYear());
    // const [selectedMonth, setSelectedMonth] = React.useState(dateObj.getUTCMonth() + 1);
    const [selectedMonth, setSelectedMonth] = React.useState("none");
    const [selectedDay, setSelectedDay] = React.useState("none");
    var devicearray = window.deviceids;

    if (devicearray.length == 0) {
        return <div>No devices connected.</div>
    }

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }


    const columns = [
        {
            field: 'verified',
            title: 'Verified'
        },
        {
            field: 'email',
            title: 'Email'
        },
        {
            field: 'verified_mailsent',
            title: 'Initial Email sent',
        },
        {
            field: 'verified_at',
            title: 'Verified at',
            // render: rowData => (
            //     <p>
            //         {
            //             addZero(new Date(rowData.verified_at).getDate()) + '.' + addZero(new Date(rowData.verified_at).getMonth() + 1) + '.' + new Date(rowData.verified_at).getFullYear()
            //             + ' ' + addZero(new Date(rowData.verified_at).getHours() + 1) + ':' + addZero(new Date(rowData.verified_at).getMinutes()) + ':' + addZero(new Date(rowData.verified_at).getSeconds())
            //         }
            //     </p>
            // ),
        },
        {
            field: 'picture_mailsent',
            title: 'Picture sent',
        },
        {
            field: 'created_at',
            title: 'Created at',
            // render: rowData => (
            //     <p>
            //         {
            //             addZero(new Date(rowData.created_at).getDate()) + '.' + addZero(new Date(rowData.created_at).getMonth() + 1) + '.' + new Date(rowData.created_at).getFullYear()
            //             + ' ' + addZero(new Date(rowData.created_at).getHours() + 1) + ':' + addZero(new Date(rowData.created_at).getMinutes()) + ':' + addZero(new Date(rowData.created_at).getSeconds())
            //         }
            //     </p>
            // ),

        },
    ];

    if (statisticsMailer == undefined) {
        var statisticstemp = {};
        for (let i = 0; i < devicearray.length; i++) {
            const boxid = devicearray[i];
            axios.post(window.serverip + "/mailerstatisticsbyboxid/" + boxid, {
                sessionid: sessionStorage.getItem("sessionid")
            })
                .then((response) => {
                    let newData = response.data
                    newData.forEach(rowData => {
                        rowData.verified_at = addZero(new Date(rowData.created_at).getFullYear()) + '-' + addZero(new Date(rowData.created_at).getMonth() + 1) + '-' + new Date(rowData.created_at).getDate()
                            + ' ' + addZero(new Date(rowData.created_at).getHours() + 1) + ':' + addZero(new Date(rowData.created_at).getMinutes()) + ':' + addZero(new Date(rowData.created_at).getSeconds())
                        rowData.created_at = addZero(new Date(rowData.created_at).getFullYear()) + '-' + addZero(new Date(rowData.created_at).getMonth() + 1) + '-' + new Date(rowData.created_at).getDate()
                            + ' ' + addZero(new Date(rowData.created_at).getHours() + 1) + ':' + addZero(new Date(rowData.created_at).getMinutes()) + ':' + addZero(new Date(rowData.created_at).getSeconds())
                    });
                    setStatisticsMailer(newData);

                }, (error) => {
                    console.log(error);
                });
        }
    }


    if (statistics == undefined) {
        var statisticstemp = {};
        for (let i = 0; i < devicearray.length; i++) {
            const boxid = devicearray[i];
            axios.post(window.serverip + "/statisticsbyboxid/" + boxid, {
                sessionid: sessionStorage.getItem("sessionid")
            })
                .then((response) => {
                    statisticstemp[boxid] = response.data;
                    var amount = 0;
                    if (Object.keys(statisticstemp).length == devicearray.length) {
                        //console.log("both fetched");
                        //console.log(statisticstemp);
                        for (const key in statisticstemp) {
                            const element = statisticstemp[key];
                            amount = amount + element.length;
                        }
                        /*if (props.imagetaken != amount) {
                            props.setImageTaken(amount);
                        }*/
                        setStatistics(statisticstemp);
                    }
                }, (error) => {
                    console.log(error);
                });
        }
    }


    var years = {};
    var yearlist = [];
    let lastyear = -1;
    let lastmonth = -1;

    for (const key in statistics) {
        const element = statistics[key];
        for (let i = 0; i < element.length; i++) {
            const event = element[i];
            var stamp = new Date(event.timestamp);
            var year = stamp.getFullYear();
            var month = stamp.getMonth() + 1;
            var day = stamp.getDate();
            if (year > lastyear) {
                lastyear = year;
            }
            if (month > lastmonth) {
                lastmonth = month;
            }

            if (years[year] == undefined) {
                years[year] = {};
                yearlist.push(year);
            }
            if (years[year][month] == undefined) {
                years[year][month] = {};
            }
            if (years[year][month][day] == undefined) {
                years[year][month][day] = [];
            }
            years[year][month][day].push(event);
        }
    }

    if (selectedYear == "none" && selectedMonth == "none") {
        if (lastyear != -1 && selectedYear != lastyear && selectedYear === "none") {
            setSelectedYear(lastyear);
        }
        if (lastmonth != -1 && selectedMonth != lastmonth && selectedMonth === "none") {
            setSelectedMonth(lastmonth);
        }
    }

    function DaySelecter() {
        if (selectedMonth != "none") {
            var amount = new Date(parseInt(selectedYear), parseInt(selectedMonth), 0).getDate();
            var darr = [];
            for (let i = 0; i < amount; i++) {
                darr.push(i + 1);
            }

            return (
                <GridItem xs={12}>
                    Selected day
                    <Select fullWidth value={selectedDay} onChange={(e) => {
                        setSelectedDay(e.target.value);
                    }}>
                        <MenuItem value={"none"} key={0}>Choose</MenuItem>
                        {
                            darr.map(function (m, i) {
                                return <MenuItem value={m} key={m}>{m}</MenuItem>;
                            })
                        }
                    </Select>
                </GridItem>
            )
        }
        return null;
    }

    function MonthSelecter() {
        if (selectedYear != "none") {
            var marr = [];
            for (let i = 0; i < 12; i++) {
                marr.push(i + 1);
            }

            return (
                <GridItem xs={12}>
                    Selected month
                    <Select fullWidth value={selectedMonth} onChange={(e) => {
                        setSelectedMonth(e.target.value);
                        setSelectedDay("none");
                    }}>
                        <MenuItem value={"none"} key={0}>Choose</MenuItem>
                        {
                            marr.map(function (m, i) {
                                return <MenuItem value={m} key={m}>{m}</MenuItem>;
                            })
                        }
                    </Select>
                </GridItem>
            )
        }
        return null;
    }

    function YearSelector() {
        return (
            <GridItem xs={12}>
                Selected year
                <Select fullWidth value={selectedYear} onChange={(e) => {
                    setSelectedYear(e.target.value);
                    setSelectedMonth("none");
                    setSelectedDay("none");
                }}>
                    <MenuItem value={"none"} key={0}>Choose</MenuItem>
                    {
                        yearlist.map(function (year, i) {
                            return <MenuItem value={year} key={year}>{year}</MenuItem>;
                        })
                    }
                </Select>
            </GridItem>
        )
    }


    return (


        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Statistics</h4>
                        <p className={classes.cardCategoryWhite}>Holy cow, a lot of pictures</p>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} md={4}>
                                {props.nobar ? null : <>
                                    <YearSelector></YearSelector>
                                </>}
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                {props.nobar ? null : <>
                                    <MonthSelecter></MonthSelecter>
                                </>}
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                {props.nobar ? null : <>
                                    <DaySelecter></DaySelecter>
                                </>}
                            </GridItem>
                            <GridItem xs={12}>
                                <DataPreparer nobar={props.nobar} years={years} selectedDay={selectedDay} selectedYear={selectedYear} selectedMonth={selectedMonth} ></DataPreparer>
                            </GridItem>

                        </GridContainer>

                    </CardBody>
                </Card>

            </GridItem>
            {statisticsMailer != undefined && !props.isDashboard ?
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Customers</h4>
                            <p className={classes.cardCategoryWhite}>Wow, look at all the customers</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>

                                <GridItem xs={12}>
                                    <UserTable
                                        style={{ padding: 5 }}
                                        columns={columns}
                                        statisticsMailer={statisticsMailer}
                                    />
                                </GridItem>


                            </GridContainer>

                        </CardBody>
                    </Card>

                </GridItem>
                : null}
        </GridContainer>

    )
}
